export const COLORS = {
    DARK_BROWN :'#482B2D',
    LIGHT_BROWN:'#5B4F43',
    BEIGE:'#F1E8E6',
    ULTRA_LIGHT_RED:'#F0E8E6',
    LIGHT_RED:'#A65528',
    DARK_RED:'#702A2C',
    LIGHT_BLUE:'#7cb3bf',
    TYPOGRAPHY:'#2222222',
    HEADING:'#482b2d',
    WHITE:'#FFFFFF'
}
